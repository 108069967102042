import { FIELD_VALIDATION } from '@bs/techconnect-ui';
import styles from './DevicesFailed.module.css';

export const FILTERS = [
  { key: 'esn', title: 'ESN', className: styles['esn-filter'], validate: v => FIELD_VALIDATION.DURATION(v, 3, 100) },
];

export const COLUMNS = [
  { key: 'esn', title: 'ESN' },
  { key: 'failedAttemptCount', title: 'Неудачных попыток' },
  { key: 'lastFailed', title: 'Последняя попытка' },
  { key: 'firmwareName', title: 'Прошивка' },
  { key: 'taskName', title: 'Задание' },
  { key: 'failReason', title: 'Причина фейла' },
  { key: 'history', title: '' },
];
