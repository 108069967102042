import { useState, useEffect } from 'react';
import constate from 'constate';
import { errorHandler } from '@bs/techconnect-ui';

function useGlobalState() {
  //-------USER-------
  const initialUser = { email: '', role: '' };
  const [user, setUser] = useState(initialUser);
  const clearUser = () => setUser(initialUser);

  //-------ERROR-------
  const [error, setError] = useState('');

  //-------SUCCESS-------
  const [success, setSuccess] = useState('');

  //-------CONFIRM UNSAVE EXIT-------
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    window.addEventListener('storage', updateError);
    return () => {
      window.removeEventListener('storage', updateError);
    };
  }, []);
  const updateError = () => {
    const { data, config } = JSON.parse(sessionStorage.getItem('error')) || {};
    const entity = config?.url?.split('/')[1];
    const errorMessage = errorHandler(data, entity);
    if (errorMessage) {
      setError(errorMessage);
      sessionStorage.removeItem('error');
    }
  };
  return { error, setError, user, setUser, clearUser, success, setSuccess, confirm, setConfirm };
}

const [StateProvider, useError, useUser, useSuccess, useConfirm] = constate(
  useGlobalState,
  v => ({ error: v.error, setError: v.setError }),
  v => ({ user: v.user, setUser: v.setUser, clearUser: v.clearUser }),
  v => ({ success: v.success, setSuccess: v.setSuccess }),
  v => ({ confirm: v.confirm, setConfirm: v.setConfirm }),
);

export { StateProvider, useError, useUser, useSuccess, useConfirm };
