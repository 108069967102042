import PropTypes from 'prop-types';

import { Button, ButtonTypes, Input, ERRORS } from '@bs/techconnect-ui';
import { ReactComponent as Delete } from 'icons/delete_s.svg';
import { ReactComponent as Add } from 'icons/plus.svg';

import styles from '../FirmwarePage.module.css';

export const TargetVersionRow = ({ index, removeRow, addRow, register }) => {
  if (addRow)
    return (
      <div className={styles['rule-row']}>
        <Button variant={ButtonTypes.I} onClick={() => addRow({ key: '', value: '' })}>
          <Add className={styles['add-icon']} />
        </Button>
        <Input className={[styles['rule-key'], styles['rule-key-empty']].join(' ')} />
        <div className={styles['rule-border']} />
      </div>
    );

  return (
    <div className={styles['rule-row']}>
      <Button variant={ButtonTypes.I} onClick={() => removeRow(index)}>
        <Delete />
      </Button>
      <Input
        className={styles['rule-key']}
        placeholder="Ключ"
        register={register(`targetVersion.${index}.key`, { required: ERRORS.REQUIRED('Ключ') })}
      />
      <div className={styles['rule-border']} />
      <div className={styles['rule-values']}>
        <Input
          className={styles['rule-add']}
          placeholder="Значение"
          register={register(`targetVersion.${index}.value`, { required: ERRORS.REQUIRED('Значение') })}
        />
      </div>
    </div>
  );
};

TargetVersionRow.defaultProps = {
  index: 0,
  removeRow: () => {},
  register: () => {},
  addRow: false,
};

TargetVersionRow.propTypes = {
  index: PropTypes.number,
  removeRow: PropTypes.func,
  addRow: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  register: PropTypes.func,
};
