export const ROLES = {
  A: 'Administrator',
  O: 'Operator',
};

export const NAV_MENU = [
  { key: '/users', title: 'Пользователи', ability: [ROLES.A] },
  { key: '/firmwares', title: 'Прошивки', ability: Object.values(ROLES) },
  { key: '/tasks', title: 'Задания на прошивку', ability: Object.values(ROLES) },
  {
    key: '/history',
    title: (
      <>
        История
        <br />
        команд
      </>
    ),
    ability: Object.values(ROLES),
  },
  { key: '/devicesfailed', title: 'Проблемные устройства', ability: Object.values(ROLES) },
  { key: '/settings', title: 'Настройки', ability: [ROLES.A] },
];

export const abilityPath = [{ key: '/', ability: Object.values(ROLES) }, ...NAV_MENU];
