import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Controller, useForm, useFieldArray } from 'react-hook-form';

import { firmwareService, taskService } from 'services';
import { decodeForm, encodeForm } from './helpers';
import { useSuccess, useConfirm } from 'helpers';
import { FIRMWARE_STATUSES } from 'components/Firmwares/helpers';
import {
  Button,
  ButtonTypes,
  ERRORS,
  Select,
  Switch,
  updateFormErrors,
  FIELD_VALIDATION,
  Input,
  InputTypes,
  Popper,
  PopperTypes,
  usePrevious,
} from '@bs/techconnect-ui';
import { CompatibilityRow } from 'components/FirmwarePage/CompatibilityRow/CompatibilityRow';
import { FirmwareCompatibilityRow } from './FirmwareCompatibilityRow/FirmwareCompatibilityRow';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { ReactComponent as Back } from 'icons/chevron.svg';
import { ReactComponent as Info } from 'icons/info.svg';
import { ReactComponent as Add } from 'icons/plus.svg';
import { ReactComponent as Upload } from 'icons/upload.svg';
import { ReactComponent as Remove } from 'icons/cross_s.svg';

import styles from './TaskPage.module.css';

const TaskPage = () => {
  const { taskId } = useParams();
  const isCreate = taskId === 'new';
  const navigate = useNavigate();
  const { setSuccess } = useSuccess();
  const { setConfirm } = useConfirm();

  const [loading, setLoading] = useState(false);
  const [loadingFirmwares, setLoadingFirmwares] = useState(false);
  const [firmwares, setFirmwares] = useState([]);
  const [firmwareCompatibility, setFirmwareCompatibility] = useState({});
  const [newDevice, setNewDevice] = useState('');
  const [getFirmwareByTask, setGetFirmwareByTask] = useState(false);
  const [exitModal, setExitModal] = useState(false);

  const navigateToList = () => {
    setConfirm(false);
    navigate('/tasks');
  };

  const {
    control,
    register,
    reset,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setError,
    watch,
  } = useForm({
    mode: 'onTouched',
    defaultValues: { timeFrom: '00:00', timeTo: '23:59', status: FIRMWARE_STATUSES.inactive },
  });

  useEffect(() => {
    setConfirm(isDirty);
  }, [isDirty, setConfirm]);

  const {
    fields: compatibility = [],
    remove: removeCompatibility,
    append: appendCompatibility,
  } = useFieldArray({ control, name: 'compatibility' });
  const {
    fields: devices = [],
    remove: removeDevice,
    append: appendDevice,
  } = useFieldArray({ control, name: 'devices' });

  const getFirmware = useCallback(
    ({ task, firmwareId }) => {
      firmwareService.getFirmware(task?.firmware?._id || firmwareId).then(({ status, data: firmware }) => {
        if (status === 200 && firmware) {
          const { form, newFirmwareCompatibility } = decodeForm(task, firmware);
          if (!firmwareId) reset(form);
          setFirmwareCompatibility(newFirmwareCompatibility);
          setGetFirmwareByTask(false);
        }
      });
    },
    [reset],
  );
  const { createTask, updateTask, getTask } = taskService;
  useEffect(() => {
    if (!isCreate) {
      setLoading(true);
      getTask(taskId)
        .then(({ status, data: task }) => {
          if (status === 200 && task) {
            const { form } = decodeForm(task);
            reset(form);
            setGetFirmwareByTask(true);
            getFirmware({ task });
          }
        })
        .finally(() => setLoading(false));
    }
  }, [taskId, isCreate, reset, getFirmware, getTask]);

  const firmwareId = watch('firmwareId');
  const prevFirmwareId = usePrevious(firmwareId);
  useEffect(() => {
    if (firmwareId && firmwareId !== prevFirmwareId && !getFirmwareByTask) {
      getFirmware({ firmwareId });
    }
  }, [firmwareId, getFirmware, getFirmwareByTask, prevFirmwareId]);

  useEffect(() => {
    // TODO: нужен инфинити лоадер
    setLoadingFirmwares(true);
    firmwareService
      .getFirmwares({ page: 1, pageSize: 100 })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) setFirmwares(data.items);
      })
      .finally(() => setLoadingFirmwares(false));
  }, []);

  const submit = form => {
    setLoading(true);
    const method = isCreate ? createTask : updateTask;
    method(encodeForm(form, firmwareCompatibility), taskId)
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data, form, setError);
        if (status === 200) {
          setSuccess(`${isCreate ? 'Задание создано' : 'Изменения сохранены'} `);
          navigateToList();
        }
      })
      .finally(() => setLoading(false));
  };

  const addNewDevice = () => {
    appendDevice({ value: newDevice });
    setNewDevice('');
  };

  const handleUploadDevices = () => {
    const input = document.getElementById('task-page-upload-devices');
    input?.click();
  };
  const uploadDevices = file => {
    const reader = new FileReader();
    reader.onload = ({ target }) => {
      if (target?.result) {
        const newDevices = [];
        const rows = target.result.split('\r\n') || [];
        rows.forEach(row => {
          const cells = row.split(';');
          cells.forEach(value => !!value && value.length < 101 && newDevices.push({ value }));
        });
        appendDevice(newDevices);
      }
    };
    reader.readAsText(file);

    //Чистить значение инпута чтобы можно было повторно загрузить тот же файл без перезагрузки
    const input = document.getElementById('task-page-upload-devices');
    input.value = '';
  };

  const exit = () => (isDirty ? setExitModal(true) : navigateToList());

  return (
    <div className={styles['wrapper']}>
      <header className={styles['header']}>
        <Button className={styles['back-btn']} variant={ButtonTypes.IR} onClick={exit}>
          <Back />
        </Button>

        <h3>{isCreate ? 'Создание' : 'Редактирование'} задания</h3>

        <Button className={styles['submit-btn']} disabled={!isValid || loading} onClick={() => handleSubmit(submit)()}>
          {isCreate ? 'Создать задание' : 'Сохранить изменения'}
        </Button>
      </header>

      <div className={styles['fields']}>
        <Input
          label="Название задания"
          placeholder=" "
          className={styles['field']}
          register={register('name', {
            required: ERRORS.REQUIRED('Название'),
            validate: v => FIELD_VALIDATION.DURATION(v, 3, 300),
          })}
          error={errors.name}
          disabled={loading}
        />

        <Controller
          control={control}
          name="firmwareId"
          rules={{ required: ERRORS.REQUIRED('Прошивка') }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <Select
              className={styles['firmware']}
              label="Прошивка"
              list={firmwares}
              idKey="_id"
              titleKey="name"
              selected={value}
              onSelect={onChange}
              onBlur={onBlur}
              error={error}
              disabled={loading || loadingFirmwares}
            />
          )}
        />

        <div className={styles['period']}>
          <Input
            label={
              <>
                <span className={styles['period-label']}>Период действия</span>
                <div className={styles['period-label']}>
                  <Popper
                    placement="right"
                    popupCls={styles['period-info']}
                    variant={PopperTypes.D}
                    popup={
                      <>
                        Если период действия определен,
                        <br />
                        то задание выполняется каждый
                        <br />
                        день только в этот период.
                        <br />
                        <br />
                        Время указывается в локальном
                        <br />
                        часовом поясе.
                      </>
                    }
                  >
                    <Info />
                  </Popper>
                </div>
              </>
            }
            placeholder=" "
            className={styles['field-time']}
            mask="99:99"
            register={register('timeFrom', {
              required: ERRORS.REQUIRED('от'),
              pattern: {
                value: new RegExp(/^(?:0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/),
                message: ERRORS.FORMAT('времени'),
              },
            })}
            error={errors.timeFrom}
            disabled={loading}
          />
          <span className={styles['period-text']}>до</span>

          <Input
            className={styles['field-time']}
            mask="99:99"
            register={register('timeTo', {
              required: ERRORS.REQUIRED('до'),
              pattern: {
                value: new RegExp(/^(?:0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/),
                message: ERRORS.FORMAT('времени'),
              },
            })}
            error={errors.timeTo}
            disabled={loading}
          />
        </div>

        <Controller
          control={control}
          name="status"
          render={({ field: { onChange, value } }) => (
            <Switch
              className={styles['status']}
              label={`Задание ${value === FIRMWARE_STATUSES.active ? 'активно' : 'неактивно'}`}
              checked={value === FIRMWARE_STATUSES.active}
              onChange={e => {
                onChange(e.target.checked ? FIRMWARE_STATUSES.active : FIRMWARE_STATUSES.inactive);
              }}
            />
          )}
        />
      </div>

      <div className={styles['compatibility']}>
        <header>Правила совместимости</header>
        {Object.keys(firmwareCompatibility).map((key, index) => (
          <FirmwareCompatibilityRow
            key={key + index}
            index={index}
            title={key}
            values={firmwareCompatibility[key]}
            onChange={newValues => setFirmwareCompatibility(prev => ({ ...prev, [key]: newValues }))}
          />
        ))}
        {compatibility.map(({ key, value }, index) => (
          <CompatibilityRow
            key={key + index}
            value={value}
            index={index}
            control={control}
            removeRow={removeCompatibility}
            register={register}
          />
        ))}
        <div className={styles['rule-row']}>
          <Button
            className={styles['compatibilty-btn']}
            variant={ButtonTypes.I}
            onClick={() => appendCompatibility({ key: '', value: [] })}
          >
            <Add className={styles['add-icon']} />
          </Button>
          <Input variant={InputTypes.L} className={[styles['rule-key'], styles['rule-key-empty']].join(' ')} />
          <div className={styles['rule-border']} />
        </div>
        <div className={styles['rule-row']} />
        <div className={styles['rule-row']}>
          <Button variant={ButtonTypes.I} onClick={handleUploadDevices}>
            <Upload />
            <input
              id="task-page-upload-devices"
              type="file"
              onChange={({ target }) => uploadDevices(target?.files[0])}
            />
          </Button>
          <div className={styles['rule-key']}>
            <div className={styles['rule-key-devices-title']}>Список устройств (ESN)</div>
            <Input variant={InputTypes.L} className={styles['rule-key-empty']} />
          </div>
          <div className={styles['rule-border']} />
          <div className={styles['rule-values']}>
            <div className={styles['rule-add-wrapper']}>
              <Input
                variant={InputTypes.L}
                className={styles['rule-add']}
                placeholder="ESN"
                value={newDevice}
                onChange={({ target }) => setNewDevice(target.value)}
                onEnter={addNewDevice}
              />
              <Add className={styles['add-icon']} onClick={addNewDevice} />
            </div>
            {devices.map(({ value }, i) => (
              <div key={'device-' + value + i} className={styles['rule-value']}>
                {value}
                <Remove onClick={() => removeDevice(i)} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={navigateToList} />
    </div>
  );
};

export default TaskPage;
