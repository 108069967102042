import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { StateProvider } from 'helpers';
import { Loader } from '@bs/techconnect-ui';
import App from 'components/App/App';

import './polyfill';
import '../node_modules/@bs/techconnect-ui/dist/styles/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StateProvider>
    <Suspense
      fallback={
        <div className="loader-wrapper">
          <Loader />
        </div>
      }
    >
      <BrowserRouter>
        <App />
        <div className="copyright">Ⓒ Technolabs</div>
      </BrowserRouter>
    </Suspense>
  </StateProvider>,
);
