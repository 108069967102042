import { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonTypes, Modal, Checkbox, CheckboxTypes } from '@bs/techconnect-ui';

import styles from './ConfirmModal.module.css';

export const ConfirmModal = ({
  open = false,
  header = '',
  description = '',
  submit = 'Да, удалить',
  cancel = 'Отмена',
  setOpen = () => {},
  updateList = () => {},
  method = () => {},
  actionHandler = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const fetch = () => {
    setLoading(true);
    method()
      .then(({ status }) => {
        if (status === 204 || status === 200) {
          updateList();
          close();
        }
      })
      .finally(() => setLoading(false));
  };

  const close = () => {
    setConfirm(false);
    setOpen();
  };

  return (
    <Modal open={open} className={styles['wrapper']}>
      <header>{header}</header>
      <Checkbox
        variant={CheckboxTypes.SS}
        className={styles['confirm']}
        label={description}
        checked={confirm}
        onChange={() => setConfirm(!confirm)}
      />
      <footer>
        <Button disabled={!confirm} onClick={actionHandler ? actionHandler : fetch} loading={loading}>
          {submit}
        </Button>
        <Button variant={ButtonTypes.S} onClick={close} disabled={loading}>
          {cancel}
        </Button>
      </footer>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  open: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  header: PropTypes.string,
  description: PropTypes.string,
  submit: PropTypes.string,
  cancel: PropTypes.string,
  setOpen: PropTypes.func,
  updateList: PropTypes.func,
  method: PropTypes.func,
  actionHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};
