import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { authService } from 'services';
import { Button, ButtonTypes, Input, InputTypes, updateFormErrors, ERRORS, FIELD_VALIDATION } from '@bs/techconnect-ui';
import { ReactComponent as Logo } from 'icons/logo.svg';

import styles from './ChangePasswordPage.module.css';

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const {
    register,
    watch,
    setError,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm({ mode: 'onTouched' });
  const password = watch('password');

  const change = form => {
    const { password } = form;
    authService.changeSelfPassword({ password }).then(({ data, status }) => {
      if (status !== 204) updateFormErrors(data, form, setError);
      if (status === 204) navigate('/');
    });
  };
  return (
    <div className={styles['wrapper']}>
      <Logo />
      <div className={styles['form']}>
        <header>Смена пароля</header>
        <Input
          type="password"
          label="Введите новый пароль"
          className={styles['field']}
          variant={InputTypes.M}
          register={register('password', {
            required: ERRORS.REQUIRED('Новый пароль'),
            pattern: FIELD_VALIDATION.PASSWORD,
          })}
          error={errors.password}
        />
        <Input
          type="password"
          label="Продублируйте новый пароль"
          className={styles['field']}
          variant={InputTypes.M}
          register={register('repeatPassword', {
            required: ERRORS.REQUIRED('Подтверждение пароля'),
            validate: v => v === password || ERRORS.PASSWORD_REPEAT,
          })}
          error={errors.repeatPassword}
        />

        <Button
          className={styles['submit']}
          type="submit"
          disabled={!isValid || isSubmitting}
          onClick={() => handleSubmit(change)()}
        >
          Сменить
        </Button>
        <Button variant={ButtonTypes.T} disabled={isSubmitting} onClick={() => navigate('/')}>
          Отмена
        </Button>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
