import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { userService } from 'services';
import { Button, ButtonTypes, InfinityScroll, Input, Modal, Popper, useDebounce } from '@bs/techconnect-ui';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { ReactComponent as Close } from 'icons/cross.svg';
import { ReactComponent as Add } from 'icons/plus.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';

import styles from './UserDevices.module.css';

export const UserDevices = ({ id, setOpen }) => {
  const {
    reset,
    register,
    formState: { isValid },
    handleSubmit,
    clearErrors,
  } = useForm({ mode: 'onTouched', defaultValues: { esn: '' } });

  // DEVICES LIST
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // SEARCH DEVICE
  const [search, setSearch] = useState('');
  const deferredSearch = useDebounce(search, 1000);

  const fetchDevices = useCallback(() => {
    setLoading(true);
    userService
      .getUserDevices(id, { page, pageSize: 8, esn: deferredSearch })
      .then(({ status, data }) => {
        if (status === 200) Array.isArray(data?.items) && setDevices(prev => [...prev, ...data.items]);
        if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
      })
      .finally(() => setLoading(false));
  }, [deferredSearch, id, page]);

  useEffect(() => {
    if (id) fetchDevices();
  }, [fetchDevices, id, page]);

  const resetList = isClose => {
    setDevices([]);
    if (page === 1 && !isClose) fetchDevices();
    else setPage(1);
  };

  // ADD DEVICE
  const [submitting, setSubmitting] = useState(false);
  const submit = form => {
    setSubmitting(true);
    userService
      .addUserDevice({ devices: [form] }, id)
      .then(({ status }) => {
        if (status === 204) {
          reset({ esn: '' });
          resetList();
        }
      })
      .finally(() => setSubmitting(false));
  };

  // DELETE DEVICE
  const [deleteModal, setDeleteModal] = useState(false);

  const renderItem = ({ esn }, index) => (
    <div key={esn + index} className={styles['list-row']}>
      <div className={styles['row-title']}>{esn}</div>
      <Popper popup="Удаление">
        <Button variant={ButtonTypes.IT} onClick={() => setDeleteModal(esn)}>
          <Delete />
        </Button>
      </Popper>
    </div>
  );

  const close = () => {
    resetList(true);
    setOpen();
    clearErrors();
  };

  return (
    <Modal open={!!id} className={styles['wrapper']}>
      <Button variant={ButtonTypes.T} onClick={close} className={styles['close']}>
        <Close />
      </Button>
      <header>Разрешенные ESN</header>

      <div className={styles['add-row']}>
        <Input
          label="Номер ESN"
          disabled={submitting}
          onEnter={() => handleSubmit(submit)()}
          register={register('esn', { required: true })}
        />

        <Button variant={ButtonTypes.I} disabled={!isValid} loading={submitting} onClick={() => handleSubmit(submit)()}>
          <Add />
        </Button>
      </div>

      <Input
        className={styles['search']}
        placeholder="Поиск ESN"
        value={search}
        onChange={e => setSearch(e.target.value)}
      />

      <InfinityScroll
        className={styles['list']}
        items={devices}
        renderItem={renderItem}
        setPage={() => setPage(prev => (prev < totalPages ? prev + 1 : page))}
        loading={loading}
        end={page === totalPages || !totalPages}
        placeholder={<div className={styles['list-placeholder']}>Отсутствуют разрешенные ESN</div>}
      />

      <Button className={styles['submit']}>Сохранить</Button>

      <ConfirmModal
        open={deleteModal}
        setOpen={() => setDeleteModal(!deleteModal)}
        updateList={resetList}
        method={() => userService.deleteUserDevice({ devices: [{ esn: deleteModal }] }, id)}
        header="Удалить устройство?"
        description="Я понимаю, что восстановить устройство будет невозможно"
      />
    </Modal>
  );
};

UserDevices.defaultProps = {
  id: '',
  setOpen: () => {},
};

UserDevices.propTypes = {
  id: PropTypes.string,
  setOpen: PropTypes.func,
};
