import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { userService } from 'services';
import { useUser } from 'helpers';
import { Loader, parseJwt } from '@bs/techconnect-ui';
import Header from '../Header/Header';
import Navigate from '../Navigate/Navigate';
import Body from '../Body/Body';

import styles from './MainLayout.module.css';

const MainLayout = () => {
  const navigate = useNavigate();
  const { setUser } = useUser();
  const [loading, setLoading] = useState(true);

  const { accessToken } = JSON.parse(localStorage.getItem('user')) || {};

  useEffect(() => {
    if (!accessToken) {
      localStorage.clear();
      navigate('/login');
    }
  }, [accessToken, navigate]);

  useEffect(() => {
    const { id } = parseJwt();
    setLoading(true);
    userService
      .getUser(id)
      .then(({ status, data }) => {
        status === 200 && setUser(data);
      })
      .finally(() => setLoading(false));
  }, [setUser]);

  if (loading)
    return (
      <div className="loader-wrapper">
        <Loader />
      </div>
    );

  return (
    <div className={styles['app']}>
      <Header />
      <Navigate />
      <Body />
    </div>
  );
};

export default MainLayout;
