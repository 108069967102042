import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { apiKeysService } from 'services';
import { useSuccess, useConfirm } from 'helpers';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { Button, ButtonTypes, SelectDate, Switch, TextArea, updateFormErrors, requestDate } from '@bs/techconnect-ui';
import { ReactComponent as Back } from 'icons/chevron.svg';

import styles from '../ApiKeyPage/ApiKeyPage.module.css';
import { SETTINGS_TABS } from 'components/Settings/helpers';

const ApiKeyPage = () => {
  const { apikeyId } = useParams();
  const navigate = useNavigate();
  const { setSuccess } = useSuccess();
  const { setConfirm } = useConfirm();

  const [loading, setLoading] = useState(false);
  const [exitModal, setExitModal] = useState(false);

  const navigateToList = () => {
    setConfirm(false);
    navigate(`/settings?tab=${SETTINGS_TABS.a}`);
  };

  const {
    handleSubmit,
    register,
    setError,
    reset,
    control,
    formState: { isDirty },
  } = useForm({ mode: 'onTouched' });

  useEffect(() => {
    setConfirm(isDirty);
  }, [isDirty, setConfirm]);

  const isCreate = apikeyId === 'new';

  useEffect(() => {
    if (!isCreate) {
      setLoading(true);
      apiKeysService
        .getApiKey(apikeyId)
        .then(({ status, data }) => {
          if (status === 200 && data) reset(data);
        })
        .finally(() => setLoading(false));
    }
  }, [isCreate, reset, apikeyId]);

  const submit = form => {
    setLoading(true);
    const { description, isBlocked } = form;
    const body = { description, isBlocked };
    const expiresAt = requestDate({ date: form.expiresAt, time: '23:59:59' });
    if (expiresAt) body.expiresAt = expiresAt;
    const method = isCreate ? apiKeysService.createApiKey : apiKeysService.updateApiKey;
    method(body, apikeyId).then(({ data, status }) => {
      if (status !== 200) updateFormErrors(data, form, setError);
      if (status === 200) {
        setSuccess('Изменения сохранены');
        navigateToList();
      }
    });
  };

  return (
    <div className={styles['wrapper']}>
      <header className={styles['header']}>
        <Button
          className={styles['back-btn']}
          variant={ButtonTypes.IR}
          onClick={() => (isDirty ? setExitModal(true) : navigateToList())}
        >
          <Back />
        </Button>
        <h3>{isCreate ? 'Создание' : 'Редактирование'} API KEY</h3>

        <Button className={styles['submit-btn']} disabled={loading} onClick={() => handleSubmit(submit)()}>
          {isCreate ? 'Создать' : 'Сохранить'} API KEY
        </Button>
      </header>

      <TextArea
        className={styles['description']}
        label="Описание API KEY"
        placeholder=" "
        register={register('description')}
      />

      <Controller
        control={control}
        name="expiresAt"
        render={({ field: { onChange, value } }) => (
          <SelectDate
            className={styles['date']}
            label="Дата истечения срока действия"
            date={value}
            setDate={onChange}
            minDate={new Date()}
          />
        )}
      />
      <Controller
        control={control}
        name="isBlocked"
        render={({ field: { onChange, value } }) => (
          <Switch
            className={styles['checkbox']}
            checked={!value}
            onChange={e => onChange(!e.target.checked)}
            label="Активность API KEY"
          />
        )}
      />
      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={navigateToList} />
    </div>
  );
};

export default ApiKeyPage;
