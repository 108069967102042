import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';

import { userService } from 'services';
import { ROLES, useConfirm, useSuccess } from 'helpers';
import { USER_FORM } from './helpers';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { Button, ButtonTypes, Input, updateFormErrors, ERRORS, Select } from '@bs/techconnect-ui';
import { ReactComponent as Back } from 'icons/chevron.svg';

import styles from './UserPage.module.css';

const UserPage = () => {
  const { userId } = useParams();
  const isCreate = userId === 'new';

  const navigate = useNavigate();
  const { setSuccess } = useSuccess();
  const { setConfirm } = useConfirm();

  const [loading, setLoading] = useState(false);
  const [exitModal, setExitModal] = useState(false);

  const navigateToList = () => {
    setConfirm(false);
    navigate('/users');
  };

  const {
    control,
    reset,
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setError,
    watch,
  } = useForm({ mode: 'onTouched' });
  const password = watch('password');

  useEffect(() => {
    setConfirm(isDirty);
  }, [isDirty, setConfirm]);

  const { getUser, createUser, updateUser } = userService;
  useEffect(() => {
    if (!isCreate) {
      setLoading(true);
      getUser(userId)
        .then(({ status, data }) => {
          if (status === 200) {
            const { name, surname, phone, role, email } = data || {};
            reset({ name, surname, phone, role: role.split(',')[0], email });
          }
        })
        .finally(() => setLoading(false));
    }
  }, [getUser, isCreate, reset, userId]);

  const submit = form => {
    setLoading(true);
    const method = isCreate ? createUser : updateUser;
    const body = isCreate ? form : { name: form.name, surname: form.surname, phone: form.phone };
    if (isCreate) delete body.repeatPassword;

    method(body, userId)
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data, form, setError);
        if (status === 200) {
          navigateToList();
          setSuccess(`${isCreate ? 'Пользователь создан' : 'Изменения сохранены'} `);
        }
      })
      .finally(() => setLoading(false));
  };

  const exit = () => (isDirty ? setExitModal(true) : navigateToList());

  const getRoleField = () => {
    return (
      <Controller
        control={control}
        name="role"
        rules={{ required: ERRORS.REQUIRED('Роль') }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <Select
            className={styles['field']}
            label="Роль"
            placeholder="Не выбрано"
            list={Object.values(ROLES).map(id => ({ id }))}
            selected={value}
            onSelect={onChange}
            onBlur={onBlur}
            error={error}
            disabled={loading || !isCreate}
            titleKey="id"
          />
        )}
      />
    );
  };
  return (
    <div className={styles['wrapper']}>
      <header className={styles['header']}>
        <Button className={styles['back-btn']} variant={ButtonTypes.IR} onClick={exit}>
          <Back />
        </Button>

        <h3>{isCreate ? 'Создание' : 'Редактирование'} пользователя</h3>

        <Button className={styles['submit-btn']} disabled={loading} onClick={() => handleSubmit(submit)()}>
          {isCreate ? 'Создать пользователя' : 'Сохранить изменения'}
        </Button>
      </header>

      {USER_FORM(password).map((row, index) => (
        <div key={'row-' + row[0].key} className={styles['fields-row']}>
          {!index && getRoleField()}
          {row.map(({ key, mask, title, options, type, visible }) =>
            visible(isCreate) ? (
              <Input
                key={key}
                type={type}
                mask={mask}
                label={title}
                className={styles['field']}
                register={register(key, options)}
                error={errors[key]}
                disabled={loading || !!(key === 'email' && !isCreate)}
              />
            ) : (
              <div key={key} className={styles['field']} />
            ),
          )}
        </div>
      ))}

      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={navigateToList} />
    </div>
  );
};

export default UserPage;
