import PropTypes from 'prop-types';

import { Checkbox, CheckboxTypes } from '@bs/techconnect-ui';
import { ReactComponent as Download } from 'icons/download.svg';

import styles from '../TaskPage.module.css';

export const FirmwareCompatibilityRow = ({ index, title, values, onChange }) => {
  return (
    <div className={styles['rule-row']}>
      <div className={styles['rule-pinned']}>
        <Download />
      </div>
      <div className={styles['rule-key']}>{title}</div>
      <div className={styles['rule-border']} />
      <div className={styles['rule-values']}>
        {Object.keys(values).map((key, i) => (
          <div key={'row-' + index + key + i} className={styles['rule-pinned-value']}>
            <span style={{ color: values[key] ? 'var(--light-black)' : 'var(--dark-grey)' }}>{key}</span>
            <Checkbox
              className={styles['rule-check']}
              checked={values[key]}
              variant={CheckboxTypes.SS}
              disabled={values[key] && Object.values(values).filter(v => v).length === 1}
              onChange={() => onChange({ ...values, [key]: !values[key] })}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

FirmwareCompatibilityRow.defaultProps = {
  index: 0,
  title: '',
  values: {},
  onChange: () => {},
};

FirmwareCompatibilityRow.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  values: PropTypes.object,
  onChange: PropTypes.func,
};
