import PropTypes from 'prop-types';

import { Button, ButtonTypes, Modal } from '@bs/techconnect-ui';

import styles from './AlertModal.module.css';

export const AlertModal = ({
  open = false,
  header = 'Покинуть эту страницу?',
  description = 'Несохраненные изменения будут потеряны',
  submit = 'Да, покинуть',
  cancel = 'Отмена',
  setOpen = () => {},
  action = () => {},
}) => {
  return (
    <Modal open={open} className={styles['wrapper']}>
      <header>{header}</header>
      <span className={styles['confirm']}>{description}</span>
      <footer>
        <Button onClick={action}>{submit}</Button>
        <Button variant={ButtonTypes.S} onClick={setOpen}>
          {cancel}
        </Button>
      </footer>
    </Modal>
  );
};

AlertModal.propTypes = {
  open: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  header: PropTypes.string,
  description: PropTypes.string,
  submit: PropTypes.string,
  cancel: PropTypes.string,
  setOpen: PropTypes.func,
  action: PropTypes.func,
};
