import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { saveAs } from 'save-as';

import { commandService } from 'services';
import { COLUMNS, STATE_ENUM, FILTERS, abilityFilter } from './helpers';
import { useUser } from 'helpers';

import { Button, ButtonTypes, ListFilters, Popper, Table, pageSize, parseDate, requestDate } from '@bs/techconnect-ui';
import { ReactComponent as Info } from 'icons/info.svg';
import { ReactComponent as Arrow } from 'icons/arrow.svg';
import { ReactComponent as Export } from 'icons/export.svg';

import styles from './CommandsHistory.module.css';

const CommandsHistory = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [expand, setExpand] = useState('');

  const {
    user: { role },
  } = useUser();
  const expandRowId = Object.keys(expand)[0];

  const filterFrom = requestDate({ date: filters?.from, time: '00:00:00' });
  const filterTo = requestDate({ date: filters?.to, time: '23:59:59' });

  const fetchCommands = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem(`historyFilters`) || '{}') || {};
    setLoading(true);
    const query = {
      ...filterData,
      from: filterFrom,
      to: filterTo,
    };
    commandService
      .getCommands({ page, pageSize, ...query })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(data.items);
          if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [page, filters]);

  useEffect(() => {
    fetchCommands();
  }, [page, filters, fetchCommands]);

  const exportData = () => {
    const { userId, taskId, firmwareId, state, esn } = filters;

    commandService
      .exportCommands({
        userId,
        taskId,
        firmwareId,
        state,
        from: filterFrom,
        to: filterTo,
        esn,
      })
      .then(({ data, status }) => {
        if (status === 200) {
          const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
          saveAs(blob, `${new Date()}.csv`);
        }
      });
  };

  const getState = ({ state, failReason }) => {
    return (
      <div className={styles['state']}>
        {STATE_ENUM[state]}
        {state === STATE_ENUM.fail.toLowerCase() && (
          <Popper placement="right" popupCls={styles['fail-reason']} popup={failReason || 'Неизвестно'}>
            <Info />
          </Popper>
        )}
      </div>
    );
  };

  const getVersionIncorrect = incorrect => {
    if (incorrect === null) return '';

    return !incorrect ? 'Соответствует' : 'Не соответствует';
  };

  const handler = row => ({
    ...row,
    createdAt: parseDate(row?.createdAt),
    completedAt: parseDate(row?.completedAt),
    task: row?.task?.name,
    firmware: row?.firmware?.name,
    email: row?.task?.createdBy?.email,
    state: getState(row || {}),
    isTargerVersionIncorrect: getVersionIncorrect(row.isTargetVersionIncorrect),
    expendArrow: (
      <Button
        variant={ButtonTypes.IT}
        onClick={() => setExpand(prev => (prev[row._id] ? '' : { [row._id]: row?.history }))}
      >
        <Arrow className={row._id === expandRowId && !!row?.history.length ? styles['arrow-up'] : ''} />
      </Button>
    ),
  });

  const extraRowHandler = row => ({
    createdAt: parseDate(row?.date),
    state: row?.state,
  });

  const action_items = [
    {
      key: 'exportVehicle',
      element: (
        <div className={styles['export']}>
          Экспорт в CSV
          <Popper popup={'Экспорт истории команд'}>
            <Button variant={ButtonTypes.S} onClick={exportData}>
              <Export />
            </Button>
          </Popper>
        </div>
      ),
    },
  ];
  return (
    <>
      <ListFilters
        title="История команд FOTA"
        fields={abilityFilter(role, FILTERS)}
        setFilters={setFilters}
        updateList={fetchCommands}
      />
      <Table
        className={styles['table']}
        columns={COLUMNS}
        rows={rows}
        totalPages={totalPages}
        loading={loading}
        idKey="_id"
        handler={handler}
        expand={expand}
        activeRow={expandRowId}
        extraRowStyle={styles['extraRowStyle']}
        extraRowHandler={extraRowHandler}
        actions={!!rows.length && action_items}
      />
    </>
  );
};

export default CommandsHistory;
