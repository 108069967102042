import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { firmwareService } from 'services';
import { COLUMNS, FIRMWARE_INDICATOR } from './helpers';
import { STATUS_ENUM } from 'components/Tasks/helpers';
import { Button, ButtonTypes, ListFilters, Popper, Table, Indicator, pageSize, parseDate } from '@bs/techconnect-ui';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as TimeLaps } from 'icons/time_lapse.svg';

import styles from './Firmwares.module.css';

const Firmwares = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);

  const fetchFirmwares = useCallback(() => {
    setLoading(true);
    firmwareService
      .getFirmwares({ page, pageSize })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(data.items);
          if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
        }
      })
      .finally(() => setLoading(false));
  }, [page]);

  useEffect(() => {
    fetchFirmwares();
  }, [page, fetchFirmwares]);

  const navHandler = id => {
    localStorage.setItem(`historyFilters`, JSON.stringify({ firmwareId: `${id}` }));
    navigate('/history');
  };

  const handler = row => ({
    ...row,
    email: row?.createdBy?.email,
    createdAt: parseDate(row?.createdAt),
    status: <Indicator status={FIRMWARE_INDICATOR[row?.status]}>{STATUS_ENUM[row?.status]}</Indicator>,
    command_history: (
      <Popper popup={'Перейти к истории команды'}>
        <Button variant={ButtonTypes.IT} onClick={() => navHandler(row?._id)}>
          <TimeLaps />
        </Button>
      </Popper>
    ),
    edit: (
      <Popper popup={'Редактирование'}>
        <Button variant={ButtonTypes.IT} onClick={() => navigate(`/firmwares/${row._id}`)}>
          <Edit />
        </Button>
      </Popper>
    ),
    delete: (
      <Popper popup={'Удаление'}>
        <Button variant={ButtonTypes.IT} onClick={() => setDeleteModal(row)}>
          <Delete />
        </Button>
      </Popper>
    ),
  });

  return (
    <>
      <ListFilters
        className={styles['header']}
        title="Прошивки"
        createHandler={() => navigate('/firmwares/new')}
        updateList={fetchFirmwares}
      />

      <Table
        className={styles['table']}
        columns={COLUMNS}
        rows={rows}
        totalPages={totalPages}
        loading={loading}
        handler={handler}
        idKey="_id"
      />

      <ConfirmModal
        open={deleteModal?._id}
        setOpen={() => setDeleteModal(!deleteModal)}
        updateList={fetchFirmwares}
        method={() => firmwareService.deleteFirmware(deleteModal?._id)}
        header="Удалить прошивку?"
        description="Я понимаю, что восстановить прошивку будет невозможно"
      />
    </>
  );
};

export default Firmwares;
