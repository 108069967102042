import { IndicatorTypes } from '@bs/techconnect-ui';

export const COLUMNS = [
  { key: 'name', title: 'Название' },
  { key: 'email', title: 'Пользователь' },
  { key: 'createdAt', title: 'Создание' },
  { key: 'status', title: 'Статус' },
  { key: 'command_history', title: '' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const FIRMWARE_STATUSES = {
  active: 'active',
  inactive: 'inactive',
};
export const FIRMWARE_INDICATOR = {
  [FIRMWARE_STATUSES.active]: IndicatorTypes.success,
  [FIRMWARE_STATUSES.inactive]: IndicatorTypes.neutral,
};

export const fileNamePlaceholder = 'Загрузить файл прошивки';
