import { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { authService } from 'services';
import { Button, ButtonTypes, Input, Modal, updateFormErrors, ERRORS, FIELD_VALIDATION } from '@bs/techconnect-ui';
import { ReactComponent as Close } from 'icons/cross.svg';
import { ReactComponent as Eye } from 'icons/eye.svg';
import { ReactComponent as EyeOff } from 'icons/eye_off.svg';

import styles from './ChangePasswordModal.module.css';

export const ChangePasswordModal = ({ id = false, open = false, setOpen = () => {} }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeat, setShowRepeat] = useState(false);
  const {
    register,
    watch,
    reset,
    setError,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm({ mode: 'onTouched' });
  const password = watch('password');

  const close = () => {
    reset({});
    setOpen();
  };

  const change = form => {
    const { password } = form;
    const method = id ? authService.changePassword : authService.changeSelfPassword;
    method({ password }, id).then(({ data, status }) => {
      if (status !== 204) updateFormErrors(data, form, setError);
      if (status === 204) close();
    });
  };

  return (
    <Modal className={styles['create-wrapper']} open={open}>
      <header>Смена пароля</header>
      <Button variant={ButtonTypes.T} className={styles['create-close']} onClick={close}>
        <Close />
      </Button>
      <div>
        <button type="button" className={styles['toggleEye']} onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <EyeOff /> : <Eye />}
        </button>
        <Input
          type={showPassword ? 'text' : 'password'}
          placeholder="Введите новый пароль"
          className={styles['params-field']}
          register={register('password', {
            required: ERRORS.REQUIRED('Новый пароль'),
            pattern: FIELD_VALIDATION.PASSWORD,
          })}
          error={errors.password}
        />
      </div>
      <div>
        <button type="button" className={styles['toggleEye']} onClick={() => setShowRepeat(!showRepeat)}>
          {showRepeat ? <EyeOff /> : <Eye />}
        </button>
        <Input
          type={showRepeat ? 'text' : 'password'}
          placeholder="Продублируйте новый пароль"
          className={styles['params-field']}
          register={register('repeatPassword', {
            required: ERRORS.REQUIRED('Подтверждение пароля'),
            validate: v => v === password || ERRORS.PASSWORD_REPEAT,
          })}
          error={errors.repeatPassword}
        />
      </div>

      <Button
        className={styles['submit']}
        type="submit"
        disabled={!isValid || isSubmitting}
        onClick={() => handleSubmit(change)()}
      >
        Сменить пароль
      </Button>
    </Modal>
  );
};

ChangePasswordModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
