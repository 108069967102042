import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import { Popup, PopupTypes, PrivateWrapper } from '@bs/techconnect-ui';
import { useError, useSuccess } from 'helpers';
import MainLayout from './MainLayout/MainLayout';
import { Login } from 'components/Login/Login';
import ChangePasswordPage from 'components/ChangePasswordPage/ChangePasswordPage';

const App = () => {
  const { error, setError } = useError();
  const { success, setSuccess } = useSuccess();
  const [popup, setPopup] = useState({ message: '' });
  const [authToken, setAuthToken] = useState(() => {
    const { accessToken } = JSON.parse(localStorage.getItem('user')) || {};
    return accessToken || '';
  });

  const handleStorageChange = () => {
    const { accessToken } = JSON.parse(localStorage.getItem('user')) || {};
    if (accessToken) setAuthToken(accessToken);
  };

  useEffect(() => {
    if (error) {
      setPopup({ message: error, variant: PopupTypes.E, time: Date.now() });
    }
    return () => {
      setError('');
    };
  }, [error, setError]);

  useEffect(() => {
    if (success) {
      setPopup({ message: success, variant: PopupTypes.S, time: Date.now() });
    }
    return () => {
      setSuccess('');
    };
  }, [success, setSuccess]);

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <>
      <Routes>
        <Route element={<PrivateWrapper isAuthenticated={authToken} isLoading={false} />}>
          <Route path="*" element={<MainLayout />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/changepass" element={<ChangePasswordPage />} />
      </Routes>
      <Popup {...popup} />
    </>
  );
};

export default App;
