import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { NAV_MENU, useUser, useConfirm } from 'helpers';
import { Button, ButtonTypes } from '@bs/techconnect-ui';
import { AlertModal } from 'components/AlertModal/AlertModal';

import styles from './Navigate.module.css';

const Navigate = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    user: { role },
  } = useUser();
  const { confirm, setConfirm } = useConfirm();
  const [exitModal, setExitModal] = useState(false);

  const abilityFilter = ({ ability = [] }) => ability.indexOf(role) + 1;

  const navigateHandler = key => {
    if (confirm && !exitModal) {
      setExitModal(key);
    } else {
      setConfirm(false);
      setExitModal(false);
      navigate(key);
    }
  };

  return (
    <nav className={styles['wrapper']}>
      {NAV_MENU.filter(abilityFilter).map(({ key, title }) => (
        <div
          key={key}
          onClick={() => navigateHandler(key)}
          className={[pathname === key && styles['item__active'], styles['item']].join(' ')}
        >
          {title}
        </div>
      ))}

      <Button className={styles['change-pass']} variant={ButtonTypes.T} onClick={() => navigate('/changepass')}>
        Сменить пароль
      </Button>

      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={() => navigateHandler(exitModal)} />
    </nav>
  );
};

export default Navigate;
