import { FIELD_VALIDATION, FilterTypes } from '@bs/techconnect-ui';
import { firmwareFilter, userFilter } from 'components/CommandsHistory/helpers';

export const COLUMNS = [
  { key: 'createdAt', title: 'Создание' },
  { key: 'name', title: 'Название' },
  { key: 'firmwareName', title: 'Прошивка' },
  { key: 'email', title: 'Пользователь' },
  { key: 'status', title: 'Статус' },
  { key: 'command_history', title: '' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const STATUS_ENUM = {
  active: 'Active',
  inactive: 'Inactive',
};

export const FILTERS = [
  { key: 'name', title: 'Название', validate: v => FIELD_VALIDATION.DURATION(v, 3, 300) },
  firmwareFilter,
  userFilter,
  {
    key: 'status',
    title: 'Статус',
    placeholder: 'Не выбрано',
    type: FilterTypes.s,
    list: Object.keys(STATUS_ENUM).map(id => ({ id, title: STATUS_ENUM[id] })),
  },
];
