import { useNavigate } from 'react-router-dom';

import { useUser } from 'helpers';
import { Button, ButtonTypes } from '@bs/techconnect-ui';
import { ReactComponent as Exit } from 'icons/exit.svg';

import styles from './Header.module.css';

const Header = () => {
  const navigate = useNavigate();
  const { user, clearUser } = useUser();

  const signout = () => {
    clearUser();
    localStorage.clear();
    navigate('/login');
  };

  return (
    <header className={styles['wrapper']}>
      <header className={styles['header']}>
        techconnect
        <b>&nbsp;FOTA Portal</b>
      </header>

      <div className={styles['profile']}>
        <span>{user.email}</span>
        <Button variant={ButtonTypes.T} onClick={signout}>
          <Exit />
        </Button>
      </div>
    </header>
  );
};

export default Header;
